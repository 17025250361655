$breakpoints: (
  xxs: '(max-width: 360px)',
  xs: '(max-width: 599px)',
  gt-xs: '(min-width: 600px)',
  lt-sm: '(max-width: 599px)',
  sm: '(min-width: 600px) and (max-width: 899px)',
  gt-sm: '(min-width: 900px)',
  lt-md: '(max-width: 899px)',
  md: '(min-width: 900px) and (max-width: 1249px)',
  gt-md: '(min-width: 1250px)',
  lt-lg: '(max-width: 1249px)',
  lg: '(min-width: 1250px) and (max-width: 1699px)',
  gt-lg: '(min-width: 1700px)',
  lt-xl: '(max-width: 1699px)',
  xl: '(min-width: 1700px) and (max-width: 5000px)',
  lt-aside: '(max-width: 1023px)',
  aside: '(min-width: 1024px)',
  lt-split-aside:
    '(min-width: 1024px) and (max-width: 1249px), (max-width: 899px)',
  split-aside: '(min-width: 900px) and (max-width: 1023px), (min-width: 1250px)',
);

@mixin mq($breakpoint) {
  $query: map-get($breakpoints, $breakpoint);
  @if $query {
    @media screen and #{$query} {
      @content;
    }
  } @else {
    @error "Undefined media query: #{$breakpoint}";
  }
}
