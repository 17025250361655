/* You can add global styles to this file, and also import other style files */
@use 'assets/styles/breakpoints';
@import 'assets/styles/ptone-app-theme';

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  background: var(--background-base);
}

body {
  font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 320px;
  height: 100%;
  background: var(--background-base);
  color: white;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

img {
  max-width: 100%;
  border: 0;
}

a {
  color: var(--bright-purple);
  text-decoration: none;
  &:active,
  &:hover {
    outline: 0;
  }
}

button {
  font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  user-select: none;
  cursor: pointer;
  &[disabled] {
    pointer-events: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  line-height: 1.25;
  margin-top: 0;
  margin-bottom: 16px;
}

h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0;
}

p {
  font-size: 16px;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}

:root {
  --background-base: #17181a;
  --background-base-a: #2a2d31;
  --background-base-b: #232528;
  --background-base-c: #202225;
  --background-lighten-1: #3c4146;
  --background-lighten-1-a: #33383d;
  --background-lighten-2: #52575c;
  --background-lighten-2-a: #5f656b;
  --background-lighten-3: #676e75;
  --background-darken-1: #101111;
  --background-darken-2: #0c0c0c;
  --background-steel-blue: #3a434c;
  --background-blue: #282d33;
  --background-blue-green: #282e34;
  --background-tan: #9e998c;
  --background-red: rgba(241, 111, 109, 0.1);
  --background-green: rgba(165, 234, 119, 0.1);
  --background-faint-purple: rgba(164, 92, 236, 0.07);
  --background-purple: rgba(164, 92, 236, 0.1);
  --background-purple-a: rgba(164, 92, 236, 0.18);
  --background-yellow: rgba(234, 175, 56, 0.1);
  --background-yellow-a: rgba(234, 175, 56, 0.2);
  --primary-text-color: white;
  --secondary-text-color: #a4a6aa;
  --tertiary-text-color: #7b7e82;
  --disabled-text-color: #5d5d5d;
  --dark-text-color: #151617;
  --divider-color: #44494f;
  --divider-color-a: #2a2d31;
  --light-divider-color: #4e555b;
  --dim-divider-color: #303235;
  --dark-divider-color: #151617;
  --purple: #a155e1;
  --bright-purple: #a45cec;
  --light-purple: rgba(164, 92, 236, 0.6);
  --yellow: #eaaf38;
  --light-yellow: #ffe396;
  --green: #59b66d;
  --light-green: #99dbaa;
  --dark-green: #003b0f;
  --blue: #1c94e0;
  --bright-blue: #00a6fd;
  --red: #c14330;
  --tan: #bfb9a8;
  --bright-red: #ff4242;
  --grayscale-1: #111111;
  --grayscale-2: #2a2a2a;
  --grayscale-3: #333333;
  --grayscale-4: #484848;
  --grayscale-5: #5d5d5d;
  --grayscale-6: #666666;
  --grayscale-7: #747474;
  --grayscale-8: #888888;
  --grayscale-9: #999999;
  --grayscale-a: #a3a3a3;
  --grayscale-b: #bbbbbb;
  --grayscale-c: #cccccc;
  --grayscale-d: #dedede;
  --grayscale-e: #e8e8e8;
  --grayscale-f: #f3f3f3;
  --grayscale-ff: #f7f7f7;
}

/*******************************
           Animations
*******************************/

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
}

/*******************************
      Container & layout
*******************************/

.container {
  max-width: 100% !important;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto !important;
  margin-right: auto !important;
  @include breakpoints.mq(xs) {
    width: 600px;
  }
  @include breakpoints.mq(sm) {
    width: 900px;
  }
  @include breakpoints.mq(md) {
    width: 1200px;
  }
  @include breakpoints.mq(lg) {
    width: 1340px;
  }
  @include breakpoints.mq(gt-lg) {
    width: 1500px;
  }
}

.slim-container {
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
  @include breakpoints.mq(gt-xs) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.narrow-container {
  max-width: 800px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.tight-container {
  max-width: 1040px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.wide-container {
  max-width: 1800px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;
}

.page-padding {
  padding-top: 24px;
}

.full-page-padding {
  padding-top: 24px;
  padding-bottom: 64px;
}

.simple-page-padding {
  padding-top: 48px;
  padding-bottom: 48px;
}

/*******************************
         Deep Overrides
*******************************/

ptone-track-edit {
  .mat-tab-body-content {
    padding-top: 4px;
  }
}

/*******************************
            Divider
*******************************/

.divider {
  width: 100%;
  height: 1px;
  border-bottom: solid 1px var(--divider-color);
  margin: 12px 0;
  &.light {
    border-color: var(--light-divider-color);
  }
  &.dim {
    border-color: var(--dim-divider-color);
  }
  &.dark {
    border-color: var(--dark-divider-color);
  }
  &.more-padding {
    margin: 24px 0;
  }
}

/*******************************
              Flex
*******************************/

.flex-spacer {
  flex-grow: 1;
}

.flex-no-shrink {
  flex-shrink: 0;
}

/*******************************
        Forms and Inputs
*******************************/

form {
  font-size: 15px;
}

.ptone-input,
div.ptone-input,
input.ptone-input,
textarea.ptone-textarea,
div.ptone-input-wrapper {
  width: 100%;
  font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 15px;
  background: var(--background-base-a);
  color: white;
  border: 1px solid var(--light-divider-color);
  border-radius: 5px;
  &:hover {
    border-color: var(--secondary-text-color);
  }
  &:focus,
  &.StripeElement--focus {
    outline: 0;
    border-color: var(--bright-blue);
  }
  &::placeholder {
    color: var(--tertiary-text-color);
  }
  &.transparent {
    background: transparent;
  }
  &.purple-border {
    border-color: var(--bright-purple);
  }
  &.yellow-border {
    border-color: var(--yellow);
  }
  &.red-border {
    border-color: var(--red);
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button,
  &[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
  &.short-input {
    max-width: 400px;
  }
  &.shorter-input {
    max-width: 180px;
  }
}

div.ptone-input,
input.ptone-input {
  line-height: 24px;
  padding: 8px 10px;
}

div.ptone-input-wrapper {
  display: flex;
  align-items: center;
  padding: 0 8px;
  &:focus-within {
    outline: 0;
    border-color: var(--bright-blue);
  }
  .ptone-input-prefix,
  .ptone-input-suffix {
    flex: 0 0 auto;
    color: var(--secondary-text-color);
  }
  .ptone-input {
    border: 0;
    background: transparent;
  }
}

textarea.ptone-textarea {
  resize: vertical;
  vertical-align: bottom;
  min-height: 90px;
  line-height: 18px;
  padding: 10px;
}

.mat-select.ptone-input {
  line-height: 36px;
  max-height: 38px;
  background: var(--background-base-a);
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid var(--light-divider-color);
  &.purple-border {
    border-color: var(--bright-purple);
  }
}

.ptone-input-header {
  display: block;
  font-size: 15px;
  margin-bottom: 6px;
  .mat-icon {
    font-size: 16px;
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }
  .helper-icon {
    cursor: pointer;
  }
}

.ptone-hint,
.ptone-warning,
.ptone-notice,
.ptone-error {
  font-size: 14px;
  margin-top: 5px;
}

.ptone-hint {
  color: var(--grayscale-a);
}

.ptone-warning {
  color: var(--yellow);
}

.ptone-notice {
  color: var(--bright-purple);
}

.ptone-error {
  color: var(--bright-red);
}

/*******************************
            Guides
*******************************/

.ptone-guide {
  p {
    font-size: 17px;
    line-height: 27px;
    a:hover {
      text-decoration: underline;
    }
  }
  ul.guide-ul,
  ol.guide-ol {
    font-size: 17px;
    line-height: 27px;
    margin-bottom: 30px;
    &.contained {
      border: 1px solid var(--divider-color);
      border-radius: 5px;
      padding: 24px 48px;
    }
    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    p {
      color: var(--secondary-text-color);
      margin: 0;
    }
    a:hover {
      text-decoration: underline;
    }
    ul.nested-ul {
      margin-top: 12px;
      li {
        &:not(:last-child) {
          margin-bottom: 4px;
        }
      }
    }
  }
  .guide-section-header {
    font-size: 27px;
    margin-top: 36px;
    margin-bottom: 16px;
  }
  .guide-note-box {
    border: 1px solid var(--divider-color);
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 16px;
  }
}

.ptone-guide-footer {
  background: var(--background-base-c);
  padding: 48px 0;
  .related-guides {
    padding-bottom: 24px;
    border-bottom: 1px solid var(--dim-divider-color);
    margin-bottom: 24px;
    .related-guides-header {
      font-size: 18px;
      margin-bottom: 12px;
    }
    ul.related-guides-ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li.related-guides-li {
        font-size: 17px;
        &:not(:last-child) {
          margin-bottom: 7px;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.ptone-guide-breadcrumbs {
  white-space: nowrap;
  font-size: 13px;
  color: var(--secondary-text-color);
  border-bottom: 1px solid var(--divider-color);
  padding-bottom: 12px;
  margin-bottom: 24px;
  .breadcrumb-link {
    color: var(--secondary-text-color);
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
  .breadcrumb-arrow {
    user-select: none;
    color: var(--tertiary-text-color);
    margin: -2px 2px 0;
  }
}

/*******************************
        MAT Autocomplete
*******************************/

.mat-mdc-autocomplete-panel.mat-mdc-autocomplete-panel {
  background-color: var(--background-base-a);
}

/*******************************
           MAT Button
*******************************/

.mat-mdc-button,
.mat-mdc-unelevated-button,
.mat-mdc-outlined-button {
  .mdc-button__label {
    max-width: 100%;
  }
  &.mat-warn {
    :not(:disabled) {
      color: var(--bright-red);
    }
  }
  &.mat-mdc-button-base {
    letter-spacing: 0.3px;
    overflow: hidden;
  }
  &.no-persistent-ripple {
    .mat-mdc-button-persistent-ripple {
      display: none;
    }
  }
  &.smallest {
    font-size: 11px;
    line-height: 25px;
    padding: 0 8px;
  }
  &.smaller {
    font-size: 12px;
    line-height: 30px;
    padding: 0 12px;
  }
  &.small {
    font-size: 13px;
    line-height: 32px;
    padding: 0 16px;
  }
  &.medium {
    line-height: 34px;
    padding: 0 16px;
  }
  &.large {
    min-width: 104px;
    font-size: 15px;
    padding: 1px 18px;
  }
  &.big {
    min-width: 104px;
    font-size: 16px;
    padding: 3px 18px;
  }
}

.mat-mdc-icon-button {
  &:not(.has-hover) {
    .mat-mdc-button-persistent-ripple {
      display: none;
    }
  }
  &.mat-mdc-button-base {
    font-size: 0;
    padding: 0;
    overflow: hidden;
  }
  &.jumbo {
    width: 64px;
    height: 64px;
  }
  &.big {
    width: 52px;
    height: 52px;
  }
  &.medium {
    width: 40px;
    height: 40px;
  }
  &.small {
    width: 36px;
    height: 36px;
  }
  &.smaller {
    width: 34px;
    height: 34px;
  }
  &.smallest {
    width: 32px;
    height: 32px;
  }
  &.tiny {
    width: 30px;
    height: 30px;
  }
  &.mini {
    width: 28px;
    height: 28px;
  }
}

.full-button {
  width: 100%;
}

/*******************************
           MAT Checkbox
*******************************/

.mat-mdc-checkbox.ptone-checkbox {
  display: block;
  border-radius: 5px;
  .mat-mdc-checkbox-ripple,
  .mdc-checkbox__ripple {
    display: none;
  }
  .mdc-form-field {
    height: 32px;
    width: 100%;
  }
  .mdc-label {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
  }
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mdc-checkbox
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background,
  .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:checked
    ~ .mdc-checkbox__background,
  .mdc-checkbox:hover
    .mdc-checkbox__native-control:enabled:indeterminate
    ~ .mdc-checkbox__background {
    background: white;
    border-color: white;
  }
  &:hover {
    background: var(--background-lighten-1-a);
  }
}

/*******************************
           MAT Dialog
*******************************/

.mat-mdc-dialog-container .mdc-dialog__container .mdc-dialog__surface {
  overflow: visible;
  background-color: var(--background-base-a);
}

.cdk-global-scrollblock {
  overflow-y: initial;
}

.cdk-overlay-dark-backdrop {
  background: rgba(black, 0.75);
}

.cdk-overlay-pane.transparent-bg
  .mat-mdc-dialog-container
  .mdc-dialog__surface {
  background-color: transparent;
  box-shadow: none;
}

.cdk-overlay-pane.darker-bg .mat-mdc-dialog-container .mdc-dialog__surface {
  background: var(--background-base-b);
}

.mat-mdc-icon-button.close-dialog-button {
  position: absolute !important;
  width: 40px;
  height: 40px;
  top: -40px;
  right: -13px;
  .mat-icon {
    color: rgba(white, 0.8);
  }
  &:hover {
    background: transparent;
    .mat-icon {
      color: white;
    }
  }
}

/*******************************
        MAT Form Field
*******************************/

.mat-mdc-form-field {
  width: 100%;
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--background-lighten-1);
  }
  &.ptone-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      border: 1px solid var(--light-divider-color);
      border-radius: 5px;
      &:hover {
        border-color: var(--secondary-text-color);
      }
      &:focus-within {
        border-color: var(--bright-blue);
      }
    }
    &.mat-focused {
      .mdc-text-field--filled {
        border-color: var(--bright-blue);
      }
    }
    &.mdc-text-field--focused {
      border-color: var(--bright-blue);
    }
    &.mat-focused .mat-mdc-select-arrow {
      color: var(--bright-blue) !important;
    }
    .mdc-line-ripple {
      display: none;
    }
    .mat-mdc-form-field-flex {
      height: 52px;
    }
    .mat-mdc-floating-label {
      display: none;
    }
    .mat-mdc-select-arrow-wrapper {
      transform: none;
    }
    .mat-mdc-form-field-infix {
      padding: 8px 0;
    }
    .mat-mdc-select {
      padding: 0;
    }
    .mat-icon {
      margin-top: -2px;
    }
  }
  &.darker-bg {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: var(--background-base-a);
    }
  }
  .mat-mdc-form-field-focus-overlay {
    display: none;
  }
  &.has-hint {
    margin-bottom: 16px;
  }
  .mat-mdc-form-field-hint {
    color: var(--secondary-text-color);
  }
}

.mat-mdc-form-field-required-marker {
  display: none;
}

.mat-error,
.mat-mdc-form-field-hint {
  color: var(--yellow);
  &.white-error,
  &.white-hint {
    color: white;
  }
}

/*******************************
           MAT Icon
*******************************/

.material-icons {
  direction: ltr;
  display: inline-block;
  font-family: 'Material Icons';
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 1;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.mat-icon {
  vertical-align: middle;
  &.size-12 {
    font-size: 12px;
    width: 12px;
    height: 12px;
    line-height: 12px !important;
  }
  &.size-13 {
    font-size: 13px;
    width: 13px;
    height: 13px;
    line-height: 13px !important;
  }
  &.size-14 {
    font-size: 14px;
    width: 14px;
    height: 14px;
    line-height: 14px !important;
  }
  &.size-15 {
    font-size: 15px;
    width: 15px;
    height: 15px;
    line-height: 15px !important;
  }
  &.size-16 {
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px !important;
  }
  &.size-17 {
    font-size: 17px;
    width: 17px;
    height: 17px;
    line-height: 17px !important;
  }
  &.size-18 {
    font-size: 18px;
    width: 18px;
    height: 18px;
    line-height: 18px !important;
  }
  &.size-19 {
    font-size: 19px;
    width: 19px;
    height: 19px;
    line-height: 19px !important;
  }
  &.size-20 {
    font-size: 20px;
    width: 20px;
    height: 20px;
    line-height: 20px !important;
  }
  &.size-21 {
    font-size: 21px;
    width: 21px;
    height: 21px;
    line-height: 21px !important;
  }
  &.size-22 {
    font-size: 22px;
    width: 22px;
    height: 22px;
    line-height: 22px !important;
  }
  &.size-23 {
    font-size: 23px;
    width: 23px;
    height: 23px;
    line-height: 23px !important;
  }
  &.size-26 {
    font-size: 26px;
    width: 26px;
    height: 26px;
    line-height: 26px !important;
  }
  &.size-28 {
    font-size: 28px;
    width: 28px;
    height: 28px;
    line-height: 28px !important;
  }
  &.size-30 {
    font-size: 30px;
    width: 30px;
    height: 30px;
    line-height: 30px !important;
  }
  &.size-32 {
    font-size: 32px;
    width: 32px;
    height: 32px;
    line-height: 32px !important;
  }
  &.size-34 {
    font-size: 34px;
    width: 34px;
    height: 34px;
    line-height: 34px !important;
  }
  &.size-36 {
    font-size: 36px;
    width: 36px;
    height: 36px;
    line-height: 36px !important;
  }
  &.size-40 {
    font-size: 40px;
    width: 40px;
    height: 40px;
    line-height: 40px !important;
  }
  &.size-48 {
    font-size: 48px;
    width: 48px;
    height: 48px;
    line-height: 48px !important;
  }
  &.size-54 {
    font-size: 54px;
    width: 54px;
    height: 54px;
    line-height: 54px !important;
  }
  &.size-60 {
    font-size: 60px;
    width: 60px;
    height: 60px;
    line-height: 60px !important;
  }
  &.size-70 {
    font-size: 70px;
    width: 70px;
    height: 70px;
    line-height: 70px !important;
  }
  &.size-80 {
    font-size: 80px;
    width: 80px;
    height: 80px;
    line-height: 80px !important;
  }
}

/*******************************
          MAT Option
*******************************/

.mat-mdc-option {
  &.mdc-list-item {
    font-size: 14px;
  }
  &.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    color: white !important;
  }
  .mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
    background-color: white;
  }
  .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
    color: white;
  }
  &.condensed {
    min-height: 40px;
  }
  &.no-checkbox .mat-pseudo-checkbox {
    display: none;
  }
}

/*******************************
           MAT Menu
*******************************/

.mat-mdc-menu-panel {
  min-height: 52px !important;
  background: var(--background-base-a) !important;
  .mat-mdc-menu-content .mat-mdc-menu-item {
    min-height: 40px;
    .mat-icon {
      transform: scale(0.8);
      color: var(--secondary-text-color);
      margin-right: 14px;
    }
    .mat-mdc-menu-item-text {
      font-size: 14px;
    }
    &.warn {
      color: var(--bright-red);
      &:hover {
        color: white;
        background-color: var(--bright-red);
      }
    }
    &.purple-menu-item {
      color: var(--bright-purple);
    }
    &:hover {
      background: var(--background-lighten-1-a);
    }
  }
  .divider {
    border-color: var(--divider-color);
    margin: 8px 0;
  }
}

.mat-mdc-menu-panel.popover-notifications-menu {
  min-width: 78vw !important;
  max-width: 78vw !important;
  @include breakpoints.mq(gt-xs) {
    min-width: 480px !important;
    max-width: 480px !important;
  }
  .mat-mdc-menu-content {
    padding: 0 !important;
  }
}

/*******************************
          MAT Select
*******************************/

.mat-mdc-select-panel {
  background-color: var(--background-base-a) !important;
  &.tall-panel {
    max-height: 480px;
  }
}

.mat-mdc-select {
  height: 40px;
  line-height: 36px !important;
  padding-left: 14px;
  padding-right: 14px;
  .mat-mdc-select-trigger {
    font-size: 15px;
  }
}

/*******************************
          MAT Slider
*******************************/

.mat-mdc-slider.ptone-audio-slider-bar {
  position: absolute;
  left: 0;
  right: 0;
  top: -8px;
  height: 18px;
  cursor: pointer;
  z-index: 4;
  padding: 0;
  margin: 0;
  pointer-events: none;
  &.mdc-slider--disabled {
    .mdc-slider__track--active,
    .mdc-slider__thumb {
      display: none;
    }
    .mdc-slider__input {
      cursor: default;
    }
  }
  .mdc-slider__thumb {
    height: 18px;
    pointer-events: none;
  }
  .mdc-slider__thumb--focused {
    .mdc-slider__thumb-knob {
      background-color: white;
      border-color: white;
    }
  }
  .mdc-slider__thumb-knob {
    width: 8px;
    height: 8px;
    border-width: 8px;
    background-color: white;
    border-color: white;
    opacity: 0;
  }
  .mat-mdc-focus-indicator {
    display: none;
  }
  .mdc-slider__track {
    height: 2px;
  }
  .mdc-slider__track--active,
  .mdc-slider__track--inactive {
    border-radius: 0;
  }
  .mdc-slider__input {
    height: 6px;
    top: 6px;
  }
  &.mdc-slider--disabled .mdc-slider__track--active_fill,
  .mdc-slider__track--inactive:before {
    background-color: white;
  }
  &:hover {
    .mdc-slider__thumb-knob {
      opacity: 1;
    }
  }
  &.smooth-transition {
    .mdc-slider__thumb,
    .mdc-slider__track--active_fill {
      transition: transform 1000ms linear;
    }
  }
}

.mat-mdc-slider.horizontal-volume-slider {
  cursor: pointer;
  z-index: 5;
  .mdc-slider__track {
    opacity: 0;
  }
  .mdc-slider__thumb-knob {
    background-color: transparent;
    border-color: white;
    width: 18px;
    height: 18px;
    border-width: 2px;
  }
  .mat-mdc-focus-indicator {
    display: none;
  }
  .mdc-slider__thumb--focused {
    .mdc-slider__thumb-knob {
      background-color: transparent;
      border-color: white;
    }
  }
}

/*******************************
       MAT Slide Toggle
*******************************/

.mat-slide-toggle.ptone-slide-toggle {
  display: block;
  font-size: 14px;
  .mat-slide-toggle-bar {
    height: 24px;
    width: 40px;
    border-radius: 100px;
    background-color: rgba(white, 0.3);
  }
  .mat-slide-toggle-thumb-container {
    top: 2px;
    left: 2px;
  }
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: var(--green);
    }
    .mat-slide-toggle-thumb {
      background-color: white;
    }
  }
}

/*******************************
          MAT Snackbar
*******************************/

.mat-mdc-snack-bar-container {
  margin-bottom: 76px !important;
  .mat-mdc-simple-snack-bar {
    text-align: center;
  }
  &.audio-snackbar {
    padding: 0;
    .mat-mdc-simple-snack-bar {
      text-align: left;
      line-height: 60px;
      padding-left: 16px;
    }
  }
}

/*******************************
        MAT Progress Bar
*******************************/

.mat-mdc-progress-bar.inverted {
  .mdc-linear-progress__buffer-bar {
    background-color: var(--grayscale-5);
  }
  .mdc-linear-progress__bar-inner {
    border-color: white;
  }
}

/*******************************
           MAT Radio
*******************************/

.mat-mdc-radio-group .mat-mdc-radio-button {
  display: flex;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background: var(--background-base-a);
  }
  .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle,
  .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-color: white !important;
  }
  .mdc-radio__background::before {
    display: none;
  }
  .mat-internal-form-field,
  .mdc-label {
    width: 100%;
    cursor: pointer;
  }
  .mdc-form-field {
    height: 32px;
    width: 100%;
  }
}

/*******************************
          MAT Spinner
*******************************/

.mat-mdc-progress-spinner {
  margin: auto;
  &.inverted {
    circle {
      stroke: white;
    }
  }
}

/*******************************
         MAT Tab Group
*******************************/

.mat-mdc-tab-group {
  .mat-mdc-tab-header {
    border-bottom: 1px solid var(--divider-color);
    margin-bottom: 24px;
  }
  .mat-mdc-tab {
    .mdc-tab__ripple,
    .mat-mdc-tab-ripple {
      display: none;
    }
    .mdc-tab-indicator__content--underline {
      border-color: white;
    }
    &.mdc-tab--active {
      .mdc-tab__text-label {
        color: white;
      }
    }

    &.mdc-tab--active:hover,
    &.mdc-tab--active:focus,
    &:hover {
      .mdc-tab__text-label {
        color: white;
      }
      .mdc-tab-indicator__content--underline {
        border-color: white;
      }
    }
  }
}

/*******************************
          MAT Tab Nav
*******************************/

.mat-mdc-tab-nav-bar {
  .mdc-tab__ripple,
  .mat-mdc-tab-ripple {
    display: none;
  }
  .mat-mdc-tab-links {
    border-bottom: 1px solid var(--divider-color);
  }
  .mat-mdc-tab-link {
    font-size: 15px;
    min-width: 64px;
    color: var(--secondary-text-color);
    letter-spacing: 0.5px;
    &:hover {
      .mdc-tab__text-label {
        color: white;
      }
    }
    &.tab-link-active,
    &.mdc-tab--active:focus,
    &.mdc-tab--active:hover {
      .mdc-tab-indicator__content--underline {
        border-color: white;
      }
      .mdc-tab__text-label {
        color: white;
      }
    }
  }
}

/*******************************
          MAT Tooltip
*******************************/

.mat-mdc-tooltip {
  font-size: 12px;
}

/*******************************
       PTONE breadcrumbs
*******************************/

.ptone-breadcrumbs {
  white-space: nowrap;
  font-size: 14px;
  color: var(--secondary-text-color);
  margin-bottom: 12px;
  .breadcrumb-link {
    color: var(--secondary-text-color);
    &:hover {
      color: white;
      text-decoration: underline;
    }
  }
  .breadcrumb-arrow {
    user-select: none;
    color: var(--tertiary-text-color);
    margin: -2px 2px 0;
  }
}

/*******************************
      PTONE Favorite-button
*******************************/

.favorite-button {
  &.liked {
    .mat-icon {
      color: var(--bright-red) !important;
    }
  }
}

/*******************************
        PTONE Logo-link
*******************************/

.ptone-logo-link {
  display: block;
  background: url('assets/images/logos/logo-inverted.svg') no-repeat;
  background-size: auto 20px;
  height: 20px;
  width: 100px;
  &:focus {
    outline: 0;
  }
  &.height-16 {
    background-size: auto 16px;
    height: 16px;
    width: 80px;
  }
  &.height-18 {
    background-size: auto 18px;
    height: 18px;
    width: 90px;
  }
  &.height-19 {
    background-size: auto 19px;
    height: 19px;
    width: 95px;
  }
  &.height-22 {
    background-size: auto 22px;
    height: 22px;
    width: 110px;
  }
  &.height-24 {
    background-size: auto 24px;
    height: 24px;
    width: 120px;
  }
  &.height-25 {
    background-size: auto 25px;
    height: 25px;
    width: 125px;
  }
  &.inverted {
    background: url('assets/images/logos/logo.svg') no-repeat;
  }
  &.all-white {
    background: url('assets/images/logos/logo-white.svg') no-repeat;
  }
}

/*******************************
      PTONE Play Indicator
*******************************/

.play-indicator-button {
  .volume-icon,
  .pause-icon {
    display: none;
  }
  &.playing {
    .play-icon {
      display: none;
    }
    .volume-icon {
      display: inline-block;
    }
    &:hover {
      .pause-icon {
        display: inline-block;
      }
      .volume-icon {
        display: none;
      }
    }
  }
}

.play-button-overlay,
.play-indicator-overlay {
  background: rgba(black, 0.6);
  display: none;
}

.play-button-overlay {
  .play-button {
    border: 1px solid rgba(white, 0.62);
    background: rgba(black, 0.2);
    color: white;
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1),
      border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    margin: auto;
    &:hover {
      border-color: white;
    }
    &:active {
      transform: scale(0.9);
    }
  }
  .play-button.play-button-purple {
    background-color: var(--purple);
    border: 0;
  }
  .mat-icon {
    margin-top: -2px;
  }
}

.play-indicator-overlay {
  user-select: none;
  .volume-on,
  .volume-off {
    margin: auto;
  }
  .volume-on {
    display: none;
  }
  &.playing {
    .volume-on {
      display: inline-block;
    }
    .volume-off {
      display: none;
    }
  }
}

/*******************************
         Ptone popup
*******************************/

.ptone-popup {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 340px;
  border-radius: 4px;
  overflow: hidden;
  font-size: 14px;
  background: white;
  color: var(--grayscale-2);
  z-index: 51;
  padding: 16px 16px 18px;
  .popup-flex-row {
    display: flex;
    align-items: center;
  }
  .close-popup-button {
    color: var(--grayscale-5);
    margin-right: -4px;
    &:hover {
      background: rgba(black, 0.1);
      color: black;
    }
  }
}

/*******************************
         PTONE Sidebar
*******************************/

.sidebar-section {
  margin-bottom: 12px;
}

.sidebar-card-section {
  border-radius: 6px;
  background: var(--background-base-b);
  padding: 18px;
  margin-bottom: 12px;
}

.sidebar-section-header {
  font-size: 17px;
  margin-bottom: 12px;
  a {
    color: inherit;
    .mat-icon {
      margin-top: -2px;
      margin-left: 2px;
    }
    &:hover {
      color: white;
      .link-text {
        text-decoration: underline;
      }
    }
  }
}

/*******************************
            Utility
*******************************/

.clearfix {
  &:before,
  &:after {
    display: block;
    content: '';
  }
  &:after {
    clear: both;
  }
}

.truncate {
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.absolute-center {
  position: absolute !important;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.absolute-fill {
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.montserrat {
  font-family: Montserrat, Roboto, Arial, Helvetica, sans-serif;
}

.semi-bold {
  font-weight: 500;
}

.bold {
  font-weight: 700;
}

.black {
  font-weight: 900;
}

.system-font {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
}

.hint-text,
.error-text {
  color: var(--yellow);
}

.purple-text {
  color: var(--bright-purple);
}

.green-text {
  color: var(--green) !important;
}

.red-text {
  color: var(--red);
}

.white-text {
  color: white;
}

.secondary-text {
  color: var(--secondary-text-color);
}

.flip-horizontal {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.flip-vertical {
  -moz-transform: scaleY(-1);
  -o-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.rotate-90 {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate-180 {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate-315 {
  -ms-transform: rotate(315deg);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}

.bullet-before::before {
  content: '•';
  font-weight: 400;
  color: var(--secondary-text-color);
  margin-left: 4px;
  margin-right: 4px;
}

.bullet-after::after {
  content: '•';
  font-weight: 400;
  color: var(--secondary-text-color);
  margin-left: 4px;
  margin-right: 4px;
}

.no-content {
  text-align: center;
  font-size: 16px;
  color: var(--secondary-text-color);
  margin-top: 48px;
  margin-bottom: 24px;
  .hero-icon {
    display: block;
    margin: 0 auto 16px;
  }
  .cta-link {
    margin-top: 18px;
  }
}

.ptone-basic-button {
  font-family: Roboto, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 500;
  background: transparent;
  color: var(--secondary-text-color);
  outline: none;
  border: 0;
  cursor: pointer;
  padding: 0;
  &:hover {
    color: white;
    .button-text {
      text-decoration: underline;
    }
  }
  .mat-icon {
    margin-top: -2px;
  }
  &.primary-color {
    color: var(--bright-purple);
  }
}

.video-thumbnail {
  background-size: cover !important;
  padding-bottom: 56.25%;
  text-align: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &.rounded {
    border-radius: 5px;
  }
  &:hover {
    .video-play-button {
      background: #ff0000;
      border-color: #ff0000;
    }
  }
  img {
    height: 100%;
  }
  .video-thumbnail-overlay {
    background: linear-gradient(
      180deg,
      rgba(30, 30, 28, 0) 0%,
      rgba(30, 30, 28, 0.9) 100%
    );
  }
  .video-play-button {
    font-size: 30px;
    width: 60px;
    height: 40px;
    line-height: 40px;
    border: 1px solid var(--secondary-text-color);
    border-radius: 5px;
    background: rgba(black, 0.65);
    transition: background-color 0.25s cubic-bezier(0, 0, 0.2, 1),
      border-color 0.25s cubic-bezier(0, 0, 0.2, 1);
  }
  &.no-video {
    cursor: auto;
    pointer-events: none;
    .video-play-button {
      display: none;
    }
  }
}

.hidden {
  display: none !important;
}

a.a-underline {
  &:hover {
    text-decoration: underline;
  }
}

.capitalize-text {
  text-transform: capitalize;
}

.ptone-link-header {
  display: inline-block;
  font-size: 16px;
  color: white;
  margin-bottom: 12px;
  &:after {
    content: '';
    display: block;
    margin-bottom: 4px;
  }
  .mat-icon {
    transition: transform 180ms cubic-bezier(0.12, 0.8, 0.32, 1);
    margin-bottom: 2px;
  }
  &:hover {
    text-decoration: underline;
    .mat-icon {
      transform: translateX(3px);
    }
  }
}

.ptone-arrow-link {
  white-space: nowrap;
  &:hover {
    .link-text {
      text-decoration: underline;
    }
  }
}
